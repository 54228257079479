export interface IDTOUsuarioPrivilegio {
    identificador: string;
    descricao: string;
    valor: boolean;
}

const listaPadraoPrivilegiosUsuario: IDTOUsuarioPrivilegio[] = [
  { identificador: 'CONFIGURACOES', descricao: 'Configurações', valor: false },
  { identificador: 'MEU_NEGOCIO', descricao: 'Meu Negócio', valor: false },
  { identificador: 'FORMAS_ENTREGAS', descricao: 'Formas de Entregas', valor: false },
  { identificador: 'REGRAS_FRETES', descricao: 'Regras de Fretes', valor: false },
  { identificador: 'FORMAS_PAGAMENTOS', descricao: 'Formas de Pagamentos', valor: false },
  { identificador: 'PERSONALIZACOES', descricao: 'Personalizações', valor: false },
  { identificador: 'CONTEUDOS', descricao: 'Conteúdos', valor: false },
  { identificador: 'USUARIOS', descricao: 'Usuários', valor: false },
  { identificador: 'CLIENTES', descricao: 'Clientes', valor: false },
  { identificador: 'PRECOS', descricao: 'Preços', valor: false },
  { identificador: 'REGRAS_PRECOS', descricao: 'Regras de Preços', valor: false },
  { identificador: 'PROD_CATEGORIAS', descricao: 'Categorias', valor: false },
  { identificador: 'PROD_MARCAS', descricao: 'Marcas', valor: false },
  { identificador: 'PROD_CARACTERISTICAS', descricao: 'Características', valor: false },
  { identificador: 'PRODUTOS', descricao: 'Produtos', valor: false },
  { identificador: 'CARRINHOS', descricao: 'Pedidos', valor: false },
  { identificador: 'PEDIDOS', descricao: 'Pedidos', valor: false },

];

export default listaPadraoPrivilegiosUsuario;
